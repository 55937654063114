/* Home.css */
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Merriweather', serif;
    background-color: #f8f9fa;
    color: #333;
  }
  
  .home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .home-main {
    max-width: 800px;
    background-color: white;
    padding: 40px;
    margin-top: 50px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .home-main h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
    text-align: left;
    margin-left: 0;
    color: #002855; /* Dark blue */
  }
  
  .home-main h2 {
    font-size: 1.5em;
    margin-bottom: 20px;
    text-align: center;
    color: #800000; /* Dark red */
  }
  
  .biography h3 {
    font-size: 1.8em;
    margin-bottom: 20px;
    text-align: center;
    color: #002855; /* Dark blue */
  }
  
  .biography p {
    margin-bottom: 20px;
    line-height: 1.8;
    font-size: 1.1em;
    color: #555;
  }
  
  /* Importing Google Font */
  @import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');
  
  .social-media-links a {
    margin-right: 15px; /* Adjust the spacing as needed */
    text-decoration: none; /* Optional: remove underline */
    color: inherit; /* Optional: inherit text color */
  }
  
  .social-media-links a:last-child {
    margin-right: 0; /* Remove margin from the last link */
  }
  